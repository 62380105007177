<template>
  <div>
    <v-row justify="center">
      <v-col lg="2" md="4" cols="10">
        <v-select
          v-model="selectAuthor"
          :items="dataAuthorsForSelection"
          :label="typeSelect === 'authors' ? 'Авторы' : 'Темы'"
          item-text="name"
          item-value="id"
          return-object
          outlined
          persistent-hint
          color="blue lighten-4"
        />
      </v-col>
      <slot />
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'SelectAuthor',
  props: {
    list: {
      type: Array,
      required: true
    },
    typeSelect: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      selectedAuthor: state => state.shared.selectedAuthor,
      selectedRubric: state => state.shared.selectedRubric,
      listRubrics: state => state.shared.listRubrics
    }),
    ...mapGetters(['filterListAuthors']),
    filterAuthors() {
      return this.filterListAuthors.filter(author => {
        if (!author.products.length) {
          return false
        } else {
          return author.products.some(product => this.list.some(item => item.id === product))
        }
      })
    },
    dataAuthorsForSelection() {
      return this.typeSelect === 'authors'
        ? [
            {
              id: 'all',
              name: 'Все'
            },
            ...this.filterAuthors.map(item => ({
              id: item.id,
              name: `${item.firstName} ${item.lastName}`
            }))
          ]
        : this.listRubrics
    },
    selectAuthor: {
      get() {
        return this.typeSelect === 'authors' ? this.selectedAuthor : this.selectedRubric
      },
      set(dataAuthor) {
        this.typeSelect === 'authors'
          ? this.$store.commit('selectAuthor', dataAuthor)
          : this.$store.commit('selectRubric', dataAuthor)
      }
    }
  },
  beforeDestroy() {
    this.$store.commit('selectAuthor', {
      id: 'all',
      author: 'Все'
    })
  }
}
</script>

<style scoped></style>
